#Content0_0 div > .ant-row > .kyed02o78t-editor_css {
  cursor: pointer;
}
#Content0_0 div > .ant-row > .kyed0s0dgz-editor_css {
  cursor: pointer;
}
#Content0_0 div > .ant-row > .kyed0uobu5l-editor_css {
  cursor: pointer;
}
#Content0_0.kyebl9mwxcr-editor_css {
  background-color: rgb(0, 21, 41);
}
#Banner2_0 .banner-anim > .banner-anim-elem > .kyebo7ez14t-editor_css {
  background-clip: ;
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-position: 0% 0%;
  background-size: auto;
}
#Nav0_0 .home-page > .kyed9pp8ojf-editor_css {
}
#Nav0_0 .kyedah9v8ap-editor_css {
}
#Content4_0.kyed31h1a2a-editor_css {
  color: #e9e9e9;
  background-color: rgb(0, 21, 41);
}
#Content4_0 .home-page > .title-wrapper > .kyed6fx98h-editor_css {
  color: #d9d9d9;
}
#Content0_0 .home-page > .title-wrapper > .kyedbq1g8m-editor_css {
  color: #bfbfbf;
}
#Content0_0 .kyebl5crbxd-editor_css {
  color: #d9d9d9;
}
#Content0_0 .ant-col > .content0-block-item > .kyedbzrdbch-editor_css {
  color: #bfbfbf;
}
#Content0_0 .ant-col > .content0-block-item > .kyedbz5vp-editor_css {
  color: #bfbfbf;
}
#Content0_0 .ant-col > .content0-block-item > .kyecxdtqba-editor_css {
  color: #bfbfbf;
}
#Content3_0.kyed2oylkbj-editor_css {
  color: #bfbfbf;
  background-color: rgb(0, 21, 41);
}
#Content3_0 .home-page > .title-wrapper > .kyed4vrhtu7-editor_css {
  color: #bfbfbf;
}
#Content3_0 .ant-col > .content3-text > .kyedchchws7-editor_css {
  color: #bfbfbf;
}
#Content3_0 .ant-col > .content3-text > .kyedcnvl9wa-editor_css {
  color: #bfbfbf;
}
#Content3_0 .ant-col > .content3-text > .kyedcq75nzh-editor_css {
  color: #bfbfbf;
}
#Content3_0 .ant-col > .content3-text > .kyedct4kdw-editor_css {
  color: #bfbfbf;
}
#Content3_0 .ant-col > .content3-text > .kyedcw4hvy-editor_css {
  color: #bfbfbf;
}
#Content3_0 .ant-col > .content3-text > .kyedcyneo3l-editor_css {
  color: #bfbfbf;
}
#Teams1_0 div > .ant-row > .kyedw97dplp-editor_css {
  cursor: pointer;
}
#Teams1_0 div > .ant-row > .kyedvvvirid-editor_css {
  cursor: pointer;
}
#Teams1_0.kyedvkyqnr-editor_css {
  background-color: rgb(0, 21, 41);
}
#Teams1_0 .kyedy94wabs-editor_css {
  color: #d9d9d9;
}
#Teams1_0 .home-page > .title-wrapper > .kyedyfjvdd9-editor_css {
  color: #d9d9d9;
}
#Teams1_0 .ant-row > .ant-col > .kyedxsu87t-editor_css {
  color: #d9d9d9;
  background-color: rgba(0, 0, 0, 0);
}
#Teams1_0 .ant-row > .ant-col > .kyedxopnq77-editor_css {
  color: #d9d9d9;
  background-color: rgba(0, 0, 0, 0);
}
#Banner0_1 .banner0-text-wrapper > .kyl5eoa6ant-editor_css {
  text-align: left;
  min-height: 50px;
  left: 30;
  float: left;
}
#Banner0_1 .kyl5ir1nxz-editor_css {
  text-align: left;
  width: 510px;
}
#Banner0_1 .banner0-text-wrapper > .kyl5ein1je-editor_css {
  width: 510px;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .kyl5nmd5j5s-editor_css {
  min-height: 50px;
  float: left;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .kyl5spjtimh-editor_css {
  min-height: 50px;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .kyl5tkcr0n-editor_css {
  height: 40px;
}
