@banner0: banner0;
.@{banner0} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url(https://github.com/RuotongX/webimg/raw/main/uidesign.png);
  background-size: contain;
  background-attachment: fixed;
  background-position:  center;

  &-text-wrapper {
    display: inline-block;
    position:relative;
    margin: auto;
    font-size: 14px;
    color: @template-text-color-light;
    width: 400px;
    text-align: center;
    left:0;
    right:0;
    top:40%;
    .@{banner0}-title {

      margin: auto;
      position: relative;
      font-size: 56px;

    }
    & .@{banner0}-content {
      word-wrap: break-word;
      margin: 50px auto 50px auto;
      font-weight: lighter;
      font-size: 15px;
      text-align: center;
    }
    button {

      border: 1px solid #fff;
      color: #fff;
      background: transparent;
      transition: background .45s @ease-in, border .45s @ease-in;
      line-height: 36px;
      font-size: 16px;
      height: 42px;
      & span {
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
        transition: text-shadow .45s @ease-out;
      }
      &:hover {
        color: #fff;
        border-color: @primary-color;
        background: @primary-color;
        transition: background .45s @ease-out, border .45s @ease-out;
        & span {
          text-shadow: 1px 1px 3px rgba(0, 0, 0, .35);
        }
      }
    }
    &:focus {
      color: #fff;
      border-color: #fff;
    }
    &.queue-anim-leaving {
      width: auto;
    }
  }
  & &-icon {
    bottom: 20px;
    font-size: 24px;
    position: absolute;
    left: 50%;
    margin-left: -12px;
    color: @template-text-color-light;
  }
}

@media screen and (max-width: 767px) {
  .@{banner0} {
    background-attachment: inherit;
    & &-text-wrapper {
      width: 90%;
    }
    & &-title {
      width: 90%;
      left: 0;
    }
  }
}
